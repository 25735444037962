import {
  DictionaryPhrases,
  ErrorPages,
  GraphQLErrorPagesService,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import clientFactory from 'lib/graphql-client-factory';
import { SitecorePageProps } from 'lib/page-props';
import { normalModePlugin } from 'lib/page-props-factory/plugins/normal-mode';
import { fetchI18nContent } from 'lib/services/common';
import { siteResolver } from 'lib/site-resolver';
import { GetServerSidePropsContext, GetStaticProps, GetStaticPropsContext } from 'next';
import Layout from 'src/Layout';
import NotFound from 'src/NotFound';
import { componentBuilder } from 'temp/componentBuilder';
import config from 'temp/config';

const Custom404 = (props: SitecorePageProps): JSX.Element => {
  if (!(props && props.layoutData)) {
    return <NotFound />;
  }

  return (
    <SitecoreContext
      componentFactory={componentBuilder.getComponentFactory()}
      layoutData={props.layoutData}
    >
      <Layout
        layoutData={props.layoutData}
        dictionary={props.dictionary}
        headLinks={props.headLinks}
      />
    </SitecoreContext>
  );
};

export const getStaticProps: GetStaticProps = async (
  context: GetServerSidePropsContext | GetStaticPropsContext
) => {
  const site = siteResolver.getByName(config.sitecoreSiteName);

  const dictionaryService = normalModePlugin.getDictionaryService(site.name);
  let dictionary: DictionaryPhrases = {};

  if (context.locale) {
    // dictionary = await dictionaryService.fetchDictionaryData(context.locale);
    dictionary = (await fetchI18nContent(context.locale).catch(() => ({})))?.content || {};
  }
  const errorPagesService = new GraphQLErrorPagesService({
    clientFactory,
    siteName: site.name,
    language: context.locale || config.defaultLanguage,
    retries:
      (process.env.GRAPH_QL_SERVICE_RETRIES &&
        parseInt(process.env.GRAPH_QL_SERVICE_RETRIES, 10)) ||
      0,
  });
  let resultErrorPages: ErrorPages | null = null;

  if (!process.env.DISABLE_SSG_FETCH) {
    try {
      resultErrorPages = await errorPagesService.fetchErrorPages();
    } catch (error) {
      console.log('Error occurred while fetching error pages');
      console.log(error);
    }
  }

  return {
    props: {
      headLinks: [],
      dictionary,
      layoutData: resultErrorPages?.notFoundPage?.rendered || null,
    },
  };
};

export default Custom404;
